import { computeDeviceModal } from "../utils/index";
import { createStore } from "vuex";
import { Log } from "../utils/index";
import wallet from "../wallet/index";
import qs from "qs";
import bignumber, { BigNumber } from "bignumber.js";
import dayjs from "dayjs";
import service from "../service/index";

const query = qs.parse(window.location.hash.replace("#/", ""), {
    ignoreQueryPrefix: true,
});
const { inviter } = query;
export default createStore({
    state: {
        defaultAddress: null,

        shortAddress: null,

        allAddress: null,

        web3JS: null,

        isMobile: false,

        isLogin: false,

        USDTBalance: 0,
        DAOBalance: 0,
        AIBalance: 0,

        approvedCountAI: 0,

        // 购买时填写邀请人
        inviter: inviter ?? "0x0000000000000000000000000000000000000000",

        isInprogressInvested: false,
        userLastInvestFinished: false,

        // 我的邀请人
        userInviter: null,
        userInvestInfo: [],
        userInfo: null,
        canByAI: true,

        allClaimedRewards: 0,
        pendingRewards: 0,

        investProducts: [],
        myTeamsAllCount: 0,
        myTeams: [],

        totalQuota: 0,
        restQuota: 0,

        subordinatesTotalUnderMe: 0,
        subordinatesInfoUnderMe: null,
        myInvestmentTotal: 0,
        subordinatesNumUnderMe: 0,

        ieo: {
            // 私募已卖份
            soldPieces: 0,
            // 私募总量
            totalPieces: 0,
            restPieces: 0,
            eachPieceAIAmount: 0,
            eachPiecePriceUSDT: 0,
            eachPiecePriceDAO: 0,
            userJoinedIEO: false,
            joinedTime: null,
            total: 0,
            ieoPaused: true,
            allowanceUsdt: 0,
            allowanceDao: 0,
        },
        ico: {
            // 私募已卖份
            soldPieces: 0,
            // 私募总量
            totalPieces: 0,
            restPieces: 0,
            eachPieceAIAmount: 0,
            eachPiecePriceUSDT: 0,
            eachPiecePriceDAO: 0,
            userJoinedICO: false,
            joinedTime: null,
            total: 0,
            icoPaused: true,
            allowanceUsdt: 0,
            allowanceDao: 0,
        },
        aiLockedAmount: 0,
        canSeeBalance: false,
        localeI18n: "en",
    },
    mutations: {
        updateAccount(state, payload) {
            const address = payload.defaultAddress;
            const shortAddress = address
                ? address.substring(0, 4) + "..." + address.substr(-4)
                : null;

            state.defaultAddress = payload.defaultAddress;
            state.shortAddress = shortAddress;
            state.allAddress = payload.allAddress;
            state.isLogin = payload.isLogin;
        },

        updateMobile(state, isMobile) {
            state.isMobile = isMobile;
        },
        updateAIBalance(state, balance) {
            state.AIBalance = balance;
        },
        updateAILockedAmount(state, amount) {
            state.aiLockedAmount = amount;
        },
        updateUSDTBalance(state, balance) {
            state.USDTBalance = balance;
        },
        updateDAOBalance(state, balance) {
            state.DAOBalance = balance;
        },
        updateApprovedCountAI(state, balance) {
            state.approvedCountAI = balance;
        },

        updateCanSeeBalance(state, isCan) {
            state.canSeeBalance = isCan;
        },

        updateInvestProducts(state, investProducts) {
            const formatList = investProducts.map((item, index) => {
                return {
                    index: index,
                    vipLevel: index,
                    currAccounts: item.currAccounts,
                    investAmount: new bignumber(item.investAmount)
                        .div(1e18)
                        .toNumber(),
                    maxAccounts: new bignumber(item.maxAccounts).toNumber(),
                    maxProfitAmount: new bignumber(item.maxProfitAmount)
                        .div(1e18)
                        .toNumber()
                        .toFixed(0),
                    releaseAmountEachDay: new bignumber(
                        item.releaseAmountEachDay
                    )
                        .div(1e18)
                        .toNumber()
                        .toFixed(2),
                    maxProfitPercent: (
                        (new bignumber(item.maxProfitAmount)
                            .div(1e18)
                            .toNumber()
                            .toFixed(0) /
                            new bignumber(item.investAmount)
                                .div(1e18)
                                .toNumber()) *
                        100
                    ).toFixed(0),
                };
            });

            let totalQuota = investProducts.reduce((total, item) => {
                return (
                    total +
                    new bignumber(item.maxAccounts).toNumber() *
                        (new bignumber(item.maxProfitAmount).toNumber() -
                            new bignumber(item.investAmount).toNumber())
                );
            }, 0);

            totalQuota = new bignumber(totalQuota)
                .div(1e18)
                .toNumber()
                .toFixed(2);

            let restQuota = investProducts.reduce((total, item) => {
                return (
                    total +
                    (new bignumber(item.maxAccounts).toNumber() -
                        new bignumber(item.currAccounts).toNumber()) *
                        (new bignumber(item.maxProfitAmount).toNumber() -
                            new bignumber(item.investAmount).toNumber())
                );
            }, 0);

            restQuota = new bignumber(restQuota)
                .div(1e18)
                .toNumber()
                .toFixed(2);

            Log.info(
                "=== updateInvestProducts ===",
                formatList,
                investProducts
            );
            Log.info(
                "=== updateInvestProducts totalQuota ===",
                totalQuota,
                restQuota
            );

            state.investProducts = formatList;
            state.totalQuota = totalQuota;
            state.restQuota = restQuota;
        },
        updateUserInfo(state, userInfo) {
            const { inviter, investInfo } = userInfo;

            const formatInviter =
                new bignumber(inviter).toNumber() === 0
                    ? null
                    : inviter.substring(0, 4) + "..." + inviter.substr(-4);

            let allClaimedRewards = 0;

            if (investInfo.length > 0) {
                allClaimedRewards = investInfo
                    .reduce((sum, cur) => {
                        return (
                            sum +
                            new bignumber(cur.claimedRewards)
                                .div(1e18)
                                .toNumber()
                        );
                    }, 0)
                    .toFixed(2);
            }

            // FIXME: 判断当前的用户是否已经购买过 & 暂时交给合约
            const isInprogressInvested = investInfo.length < 0;

            const investInfoList = investInfo.map((item) => {
                return {
                    status:
                        new bignumber(item.maxProfitAmount) ===
                        new bignumber(item.claimedRewards)
                            ? "Completed"
                            : "Inprogress",
                    claimedDays: item.claimedDays,
                    claimedRewards: new bignumber(item.claimedRewards)
                        .div(1e18)
                        .toNumber()
                        .toFixed(2),
                    investAmount: new bignumber(item.investAmount)
                        .div(1e18)
                        .toNumber(),
                    investTimestamp: dayjs(
                        new bignumber(item.investTimestamp)
                            .times(1000)
                            .toNumber()
                    ).format("YYYY-MM-DD HH:mm:ss"),
                    maxProfitAmount: new bignumber(item.maxProfitAmount)
                        .div(1e18)
                        .toNumber()
                        .toFixed(0),
                    pendingReferRewards: new bignumber(item.pendingReferRewards)
                        .div(1e18)
                        .toNumber()
                        .toFixed(2),
                    releaseAmountEachDay: new bignumber(
                        item.releaseAmountEachDay
                    )
                        .div(1e18)
                        .toNumber()
                        .toFixed(2),
                };
            });

            Log.info("=========  investInfoList  =========", investInfoList);

            state.userInviter = formatInviter;
            state.userInvestInfo = investInfoList;
            state.userInfo = userInfo;
            // test
            // state.userInvestInfo = [{
            //   status: 'Completed',
            //   investTimestamp: '2023-09-09',
            //   investAmount: 12,
            //   claimedRewards: '22'
            // }, {
            //   status: 'Inprogress',
            //   investTimestamp: '2023-09-09',
            //   investAmount: 12,
            //   claimedRewards: '22'
            // }]
            state.isInprogressInvested = isInprogressInvested;
            state.allClaimedRewards = allClaimedRewards;
        },

        updatePendingRewards(state, pending) {
            state.pendingRewards = new bignumber(pending.pendingRewards)
                .div(1e18)
                .toNumber()
                .toFixed(2);
        },

        updateInvestInfoUnderMe(state, investInfo) {
            const {
                subordinatesTotalUnderMe,
                subordinatesInfoUnderMe,
                myInvestmentTotal,
                subordinatesNumUnderMe,
            } = investInfo;
            state.subordinatesTotalUnderMe = subordinatesTotalUnderMe;
            state.subordinatesInfoUnderMe = subordinatesInfoUnderMe;
            state.myInvestmentTotal = myInvestmentTotal;
            state.subordinatesNumUnderMe = subordinatesNumUnderMe;
        },

        // 私募数量
        updateIEOInfo(state, placementInfo) {
            const {
                soldPieces,
                totalPieces,
                eachPieceAIAmount,
                eachPiecePriceUSDT,
                eachPiecePriceDAO,
                idoPaused,
            } = placementInfo;
            const restPieces =
                new bignumber(totalPieces).toNumber() -
                new bignumber(soldPieces).toNumber();

            state.ieo.soldPieces = new bignumber(soldPieces)
                .toNumber()
                .toFixed(0);
            state.ieo.totalPieces = new bignumber(totalPieces)
                .toNumber()
                .toFixed(0);
            state.ieo.restPieces = new bignumber(restPieces)
                .toNumber()
                .toFixed(0);

            state.ieo.eachPieceAIAmount = new bignumber(eachPieceAIAmount)
                .div(1e18)
                .toNumber()
                .toFixed(2);
            state.ieo.eachPiecePriceUSDT = new bignumber(eachPiecePriceUSDT)
                .div(1e18)
                .toNumber()
                .toFixed(2);
            state.ieo.eachPiecePriceDAO = new bignumber(eachPiecePriceDAO)
                .div(1e18)
                .toNumber()
                .toFixed(2);

            state.ieo.idoPaused = idoPaused;
        },
        updateUserJoinedIEOInfo(state, info) {
            state.ieo.userJoinedIEO = info.joined;
            state.ieo.joinedTime = info.joinedTimestamp;
            state.ieo.total = info.total;
        },
        updateICOInfo(state, placementInfo) {
            const {
                soldPieces,
                totalPieces,
                eachPieceAIAmount,
                eachPiecePriceUSDT,
                eachPiecePriceDAO,
                idoPaused,
            } = placementInfo;
            const restPieces =
                new bignumber(totalPieces).toNumber() -
                new bignumber(soldPieces).toNumber();

            console.log(
                "===updateIEOInfo===",
                restPieces,
                new bignumber(restPieces),
                new bignumber(restPieces).toNumber(),
                new bignumber(restPieces).toNumber().toFixed(0)
            );
            state.ico.soldPieces = new bignumber(soldPieces)
                .toNumber()
                .toFixed(0);
            state.ico.totalPieces = new bignumber(totalPieces)
                .toNumber()
                .toFixed(0);
            state.ico.restPieces = new bignumber(restPieces)
                .toNumber()
                .toFixed(0);

            state.ico.eachPieceAIAmount = new bignumber(eachPieceAIAmount)
                .div(1e18)
                .toNumber()
                .toFixed(2);
            state.ico.eachPiecePriceUSDT = new bignumber(eachPiecePriceUSDT)
                .div(1e18)
                .toNumber()
                .toFixed(2);

            state.ico.eachPiecePriceDAO = new bignumber(eachPiecePriceDAO)
                .div(1e18)
                .toNumber()
                .toFixed(2);

            state.ico.idoPaused = idoPaused;
        },
        updateUserJoinedICOInfo(state, info) {
            state.ico.userJoinedICO = info.joined;
            state.ico.joinedTime = info.joinedTimestamp;
            state.ico.total = info.total;
        },
        updateLocaleI18n(state, i18n) {
            state.localeI18n = i18n;
        },
        updateInviter(state, inviter) {
            state.inviter = inviter;
        },
        updateUserLastInvestFinished(state, userLastInvestFinished) {
            state.userLastInvestFinished = userLastInvestFinished;
        },
        updateMyTeams(state, teams) {
            state.myTeams = teams;
            let count = 0;

            for (let i = 0; i < teams.length; i++) {
                count += teams[i].length;
            }

            state.myTeamsAllCount = count;
        },
        updateIEOAllowanceUSDT(state, amount) {
            state.ieo.allowanceUsdt = amount;
        },
        updateICOAllowanceUSDT(state, amount) {
            state.ico.allowanceUsdt = amount;
        },
        updateIEOAllowanceDAO(state, amount) {
            state.ieo.allowanceDao = amount;
        },
        updateICOAllowanceDAO(state, amount) {
            state.ico.allowanceDao = amount;
        },
    },
    actions: {
        loginMetamask({ commit }) {
            wallet
                .loginMetamask()
                .then((res) => {
                    commit("updateAccount", {
                        defaultAddress: res[0],
                        allAddress: res,
                        isLogin: true,
                    });
                })
                .catch((err) => {
                    Log.info(err);
                });
        },

        silentLogin({ commit }) {
            return wallet
                .initAccount()
                .then((res) => {
                    commit("updateAccount", {
                        defaultAddress: res[0],
                        allAddress: res,
                        isLogin: true,
                    });
                    return res[0];
                })
                .catch((err) => {
                    Log.warn("SilentLogin Failed! User Need Login!", err);
                });
        },
        getUSDTBalance({ commit }) {
            return wallet
                .getUSDTBalance()
                .then((res) => {
                    const balance = new bignumber(res)
                        .dividedBy(1e18)
                        .toNumber();
                    Log.info("===  getUSDTBalance ===", balance);
                    commit("updateUSDTBalance", balance);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getUSDTBalance ===", err);
                });
        },
        getDAOBalance({ commit }) {
            return wallet
                .getDAOBalance()
                .then((res) => {
                    const balance = new bignumber(res)
                        .dividedBy(1e18)
                        .toNumber();
                    Log.info("===  getDAOBalance ===", balance);
                    commit("updateDAOBalance", balance);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getDAOBalance ===", err);
                });
        },
        getAIBalance({ commit }) {
            return wallet
                .getAIBalance()
                .then((res) => {
                    const balance = new bignumber(res)
                        .dividedBy(1e18)
                        .toNumber();
                    Log.info("===  getAIBalance ===", balance);
                    commit("updateAIBalance", balance);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getAIBalance ===", err);
                });
        },
        getLockedAmount({ commit }) {
            return wallet
                .getLockedAmount()
                .then((res) => {
                    const balance = new bignumber(res)
                        .dividedBy(1e18)
                        .toNumber();
                    Log.info("===  getLockedAmount ===", balance);
                    commit("updateAILockedAmount", balance);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getLockedAmount ===", err);
                });
        },
        getApprovedCountAI({ commit }) {
            return wallet
                .getApprovedCountAI()
                .then((res) => {
                    const balance = new bignumber(res)
                        .dividedBy(1e18)
                        .toNumber();
                    Log.info("===  allowance allowance ===", balance);
                    commit("updateApprovedCountAI", balance);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== allowance ===", err);
                });
        },

        approveAIToInvest() {
            return wallet
                .approveAIToInvest()
                .then((res) => {
                    Log.info("=== approveAIToInvest ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== approveAIToInvest ===", err);
                });
        },

        buyAI(_, { vipLevel }) {
            console.log(this.state.inviter);
            return wallet.buyAI({
                vipLevel: vipLevel,
                inviter: this.state.inviter,
            });
            // .then(res => {
            //   Log.info('=== buyAI ===', res)
            //   return res
            // })
            // .catch(err => {
            //   Log.warn('=== buyAI ===', err)
            // })
        },

        getUserInfo({ commit }) {
            return wallet
                .getUserInfoLite()
                .then((res) => {
                    Log.info("=== getUserInfo ===", res);
                    commit("updateUserInfo", res);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getUserInfo  err===", err);
                });
        },

        pendingInviteRewards() {
            return wallet
                .pendingInviteRewards()
                .then((res) => {
                    Log.info("=== pendingInviteRewards ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.error("=== pendingInviteRewards err===", err);
                });
        },

        getUserInfoTeams({ commit }) {
            Promise.all([
                wallet.getUserInfoTeams(1),
                wallet.getUserInfoTeams(2),
                wallet.getUserInfoTeams(3),
                wallet.getUserInfoTeams(4),
                wallet.getUserInfoTeams(5),
                wallet.getUserInfoTeams(6),
                wallet.getUserInfoTeams(7),
                wallet.getUserInfoTeams(8),
                wallet.getUserInfoTeams(9),
                wallet.getUserInfoTeams(10),
                wallet.getUserInfoTeams(11),
            ])
                .then((teams) => {
                    Log.info("=== getUserInfoTeams ===", teams);
                    commit("updateMyTeams", teams);
                    return teams;
                })
                .catch((err) => {
                    Log.error("=== getUserInfoTeams err===", err);
                });
        },

        getPendingRewards({ commit }) {
            return wallet
                .getPendingRewards()
                .then((res) => {
                    Log.info("=== getPendingRewards ===", res);
                    commit("updatePendingRewards", {
                        pendingRewards: res.amount,
                    });
                    return res;
                })
                .catch((err) => {
                    Log.error("=== getPendingRewards  err===", err);
                });
        },

        getInvestProducts({ commit }) {
            return Promise.all(
                [0, 1, 2, 3, 4].map((level) => {
                    return wallet.getInvestProducts(level).then((res) => {
                        return res;
                    });
                })
            ).then((investProducts) => {
                Log.info("=== getInvestProducts ===", investProducts);
                commit("updateInvestProducts", investProducts);
                return investProducts;
            });
        },

        claimRewards() {
            return wallet.claimRewards();
            // .then(res => {
            //   Log.info('=== claimRewards ===', res)
            //   return res
            // })
            // .catch(err => {
            //   Log.error('=== claimRewards  err===', err)
            // })
        },

        claimInviteRewards() {
            return wallet.claimInviteRewards();
        },

        doMobileUpdate({ commit }) {
            const isMobile = computeDeviceModal().isMobile;
            commit("updateMobile", isMobile);
        },

        doBalanceFlagUpdate({ commit }) {
            commit("updateCanSeeBalance", !this.state.canSeeBalance);
        },

        changeLocaleI18n({ commit }, { locale }) {
            commit("updateLocaleI18n", locale);
        },
        userRegistrations({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");
            console.log(this.state.inviter);
            return wallet
                .userRegistrations(this.state.inviter)
                .then((res) => {
                    Log.info("=== userRegistrations ===", res);
                    if (res === false) {
                        commit("updateInviter", "");
                    }
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== userRegistrations ===", err);
                    commit("updateInviter", "");
                });
        },
        userLastInvestFinished({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");

            return wallet
                .userLastInvestFinished()
                .then((res) => {
                    Log.info("=== userLastInvestFinished ===", res);
                    commit("updateUserLastInvestFinished", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== userLastInvestFinished ===", err);
                    commit("updateUserLastInvestFinished", false);
                });
        },

        // 我的下级
        getInvestInfoUnderMe({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");
            return service
                .getFormatSubordinatesInfo({
                    address: this.state.defaultAddress,
                })
                .then(
                    ({
                        subordinatesTotalUnderMe,
                        subordinatesInfoUnderMe,
                        myInvestmentTotal,
                        subordinatesNumUnderMe,
                    }) => {
                        Log.info(
                            "=== getInvestInfoUnderMe ===",
                            subordinatesTotalUnderMe,
                            subordinatesInfoUnderMe,
                            subordinatesNumUnderMe
                        );
                        commit("updateInvestInfoUnderMe", {
                            subordinatesTotalUnderMe,
                            subordinatesInfoUnderMe,
                            myInvestmentTotal,
                            subordinatesNumUnderMe,
                        });
                    }
                );
        },
        // 私募信息IEO
        getAIIEOInfo({ commit }) {
            return Promise.all([
                wallet.getIEOSoldPieces().then((res) => {
                    Log.info("=== getIEOSoldPieces ===", res);
                    return res;
                }),
                wallet.getIEOTotalPieces().then((res) => {
                    Log.info("=== getIEOTotalPieces ===", res);
                    return res;
                }),
                wallet.getIEOEachPieceAIAmount().then((res) => {
                    Log.info("=== getIEOEachPieceAIAmount ===", res);
                    return res;
                }),
                wallet.getIEOEachPiecePriceUSDT().then((res) => {
                    Log.info("=== getIEOEachPiecePriceUSDT ===", res);
                    return res;
                }),
                wallet.getIEOEachPiecePriceDAO().then((res) => {
                    Log.info("=== getIEOEachPiecePriceDAO ===", res);
                    return res;
                }),
                wallet.getIEOPaused().then((res) => {
                    Log.info("=== getIEOPaused ===", res);
                    return res;
                }),
            ]).then(
                ([
                    soldPieces,
                    totalPieces,
                    eachPieceAIAmount,
                    eachPiecePriceUSDT,
                    eachPiecePriceDAO,
                    idoPaused,
                ]) => {
                    Log.info(
                        "=== getAIIEOInfo ===",
                        soldPieces,
                        totalPieces,
                        eachPieceAIAmount,
                        eachPiecePriceUSDT,
                        eachPiecePriceDAO,
                        idoPaused
                    );
                    commit("updateIEOInfo", {
                        soldPieces,
                        totalPieces,
                        eachPieceAIAmount,
                        eachPiecePriceUSDT,
                        eachPiecePriceDAO,
                        idoPaused,
                    });
                }
            );
        },
        // 私募信息ICO
        getAIICOInfo({ commit }) {
            return Promise.all([
                wallet.getICOSoldPieces().then((res) => {
                    Log.info("=== getICOSoldPieces ===", res);
                    return res;
                }),
                wallet.getICOTotalPieces().then((res) => {
                    Log.info("=== getICOTotalPieces ===", res);
                    return res;
                }),
                wallet.getICOEachPieceAIAmount().then((res) => {
                    Log.info("=== getICOEachPieceAIAmount ===", res);
                    return res;
                }),
                wallet.getICOEachPiecePriceUSDT().then((res) => {
                    Log.info("=== getICOEachPiecePriceUSDT ===", res);
                    return res;
                }),
                wallet.getICOEachPiecePriceDAO().then((res) => {
                    Log.info("=== getICOEachPiecePriceDAO ===", res);
                    return res;
                }),
                wallet.getICOPaused().then((res) => {
                    Log.info("=== getICOPaused ===", res);
                    return res;
                }),
            ]).then(
                ([
                    soldPieces,
                    totalPieces,
                    eachPieceAIAmount,
                    eachPiecePriceUSDT,
                    eachPiecePriceDAO,
                    idoPaused,
                ]) => {
                    Log.info(
                        "=== getAIICOInfo ===",
                        soldPieces,
                        totalPieces,
                        eachPieceAIAmount,
                        eachPiecePriceUSDT,
                        eachPiecePriceDAO,
                        idoPaused
                    );
                    commit("updateICOInfo", {
                        soldPieces,
                        totalPieces,
                        eachPieceAIAmount,
                        eachPiecePriceUSDT,
                        eachPiecePriceDAO,
                        idoPaused,
                    });
                }
            );
        },
        getUserIDOInfo({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");

            return Promise.all([
                wallet.getUserIEOInfo(),
                wallet.getUserICOInfo(),
            ]).then(([ieo, ico]) => {
                Log.info("=== getUserIEOInfo ===", ieo);
                Log.info("=== getUserICOInfo ===", ico);
                commit("updateUserJoinedIEOInfo", ieo);
                commit("updateUserJoinedICOInfo", ico);
            });
        },
        approveUSDT2IEO() {
            return wallet
                .approveUSDT2IEO()
                .then((res) => {
                    Log.info("=== approveUSDT2IEO ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== approveUSDT2IEO ===", err);
                });
        },
        approveUSDT2ICO() {
            return wallet
                .approveUSDT2ICO()
                .then((res) => {
                    Log.info("=== approveUSDT2ICO ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== approveUSDT2ICO ===", err);
                });
        },
        approveDAO2IEO() {
            return wallet
                .approveDAO2IEO()
                .then((res) => {
                    Log.info("=== approveDAO2IEO ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== approveDAO2IEO ===", err);
                });
        },
        approveDAO2ICO() {
            return wallet
                .approveDAO2ICO()
                .then((res) => {
                    Log.info("=== approveDAO2ICO ===", res);
                    return res;
                })
                .catch((err) => {
                    Log.warn("=== approveDAO2ICO ===", err);
                });
        },
        joinAIIEO() {
            return wallet.joinAIIEO();
            // .then(res => {
            //   Log.info('=== joinOXCIDO ===', res)
            //   return res
            // })
            // .catch(err => {
            //   Log.warn('=== joinOXCIDO ===', err)
            // })
        },
        joinAIICO() {
            return wallet.joinAIICO();
            // .then(res => {
            //   Log.info('=== joinOXCIDO ===', res)
            //   return res
            // })
            // .catch(err => {
            //   Log.warn('=== joinOXCIDO ===', err)
            // })
        },
        getApprovedCountUSDTToIDO({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");

            return Promise.all([
                wallet.getApprovedCountUSDTToIEO(),
                wallet.getApprovedCountUSDTToICO(),
            ]).then(([ieo, ico]) => {
                Log.info("=== getApprovedCountUSDTToIEO ===", ieo);
                Log.info("=== getApprovedCountUSDTToICO ===", ico);

                commit(
                    "updateIEOAllowanceUSDT",
                    new BigNumber(ieo).div(1e18).toNumber()
                );
                commit(
                    "updateICOAllowanceUSDT",
                    new BigNumber(ico).div(1e18).toNumber()
                );
            });
        },
        getApprovedCountDAOToIDO({ commit }) {
            if (!this.state.defaultAddress)
                return Promise.reject("No Default Address");

            return Promise.all([
                wallet.getApprovedCountDAOToIEO(),
                wallet.getApprovedCountDAOToICO(),
            ]).then(([ieo, ico]) => {
                Log.info("=== getApprovedCountDAOToIEO ===", ieo);
                Log.info("=== getApprovedCountDAOToICO ===", ico);

                commit(
                    "updateIEOAllowanceDAO",
                    new BigNumber(ieo).div(1e18).toNumber()
                );
                commit(
                    "updateICOAllowanceDAO",
                    new BigNumber(ico).div(1e18).toNumber()
                );
            });
        },
    },
    modules: {},
    getters: {},
});
