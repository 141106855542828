// WEIYA: 函数式组建需要手动引入
import "element-plus/es/components/message/style/css";
import ElementPlus from "element-plus";
import { createApp } from "vue";

import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "./styles/index.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import SvgIcon from "./components/SvgIcon/Icon.vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.component("svg-icon", SvgIcon);

app.use(i18n).use(store).use(router).use(ElementPlus).mount("#app");
