export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet verbinden"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanisch"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreanisch"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabisch"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinesisch"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Informationen"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontostand"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investieren"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruch"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweiser"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Einladender"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Einladung"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweislink"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopieren"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufzeichnung"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbelohnungen"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionssumme"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsdatum"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restliches Kontingent"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtes Kontingent"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Gewinnbetrag"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Anzahl an Konten"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Konten"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabebetrag pro Tag"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsatz"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Investition"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO bereits getauscht"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bereits getauscht"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtanzahl IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Tausch teilnehmen"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tauschen"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one mortgage level can be selected for each account at the same time. \nAfter the release is completed and the rewards are claimed, you can choose to participate in staking again."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only users who are mining are eligible to receive invitation rewards. Invitations sent by users who are not mining will not receive rewards."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total invitation rewards"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation link"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining records"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient token balance"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the inviter"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid address"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inviter is invalid. The inviter must be a registered user!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users who invite new users to participate in the project can receive corresponding rewards. \nInvitation rewards are calculated separately according to different levels, as follows:"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Reward"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invitations"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Incentives"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining Count"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incentive rate"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rewards"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Rewards"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enable"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Price"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Invitation Rewards"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlocked Rewards"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked Rewards"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including"])}
}