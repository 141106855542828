export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वॉलेट कनेक्ट करें"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंग्रेज़ी"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जापानी"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोरियाई"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिन्दी"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूसी"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जर्मन"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़्रेंच"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुर्तगाली"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पेनिश"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अरबी"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चीनी"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत जानकारी"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष राशि"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले से ही"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवेश"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दावा"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेफर"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आमंत्रितकर्ता नहीं"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरा निमंत्रण"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेफरल लिंक"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक कॉपी करें"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिकॉर्ड"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवेश रिवॉर्ड"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवेश राशि"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवेश तारीख"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रगति में"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्ति"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्तर"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष कोटा"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल कोटा"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम लाभ राशि"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम खाते"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान खाते"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिदिन जारी राशि"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवेश करना"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरा निवेश"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO पहले से ही एक्सचेंज हो गया है"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले से ही एक्सचेंज हो गया है"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज में भाग लें"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिस्सा"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खत्म करना"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक ही समय में प्रत्येक खाते के लिए केवल एक बंधक स्तर का चयन किया जा सकता है। \nरिलीज़ पूरी होने और पुरस्कारों का दावा किए जाने के बाद, आप फिर से दांव में भाग लेना चुन सकते हैं।"])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल वे उपयोगकर्ता जो खनन कर रहे हैं वे आमंत्रण पुरस्कार प्राप्त करने के पात्र हैं। जो उपयोगकर्ता खनन नहीं कर रहे हैं उनके द्वारा भेजे गए आमंत्रणों को पुरस्कार नहीं मिलेगा।"])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल आमंत्रण पुरस्कार"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रण लिंक"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खनन रिकार्ड"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई रिकॉर्ड नहीं"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपर्याप्त टोकन शेष"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया आमंत्रणकर्ता दर्ज करें"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमान्य पता"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रणकर्ता अमान्य है. आमंत्रणकर्ता को एक पंजीकृत उपयोगकर्ता होना चाहिए!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जो उपयोगकर्ता नए उपयोगकर्ताओं को परियोजना में भाग लेने के लिए आमंत्रित करते हैं, वे संबंधित पुरस्कार प्राप्त कर सकते हैं। \nआमंत्रण पुरस्कारों की गणना अलग-अलग स्तरों के अनुसार अलग-अलग की जाती है, इस प्रकार:"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपूर्ण इनाम"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल निमंत्रण"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमंत्रण प्रोत्साहन"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आय"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खनन गणना"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोत्साहन दर"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुदाई"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल पुरस्कार"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दैनिक पुरस्कार"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्षम"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खरीदना"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य खरीदें"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित आमंत्रण पुरस्कार"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनलॉक पुरस्कार"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद पुरस्कार"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शामिल"])}
}