const DEV_ENV = process.env.NODE_ENV === 'development'
class JsLog {
  constructor() {
    const switchLocal = localStorage.getItem('log-switch') || ''
    this.logSwitch = switchLocal == 'true' || false

    if (DEV_ENV) this.openLog()
  }

  openLog() {
    this.logSwitch = true
    localStorage.setItem('log-switch', 'true')
  }

  closeLog() {
    this.logSwitch = true
    localStorage.setItem('log-switch', 'false')
  }

  log(...args) {
    if (this.logSwitch) {
      console.log(...args)
    } else {
      return false
    }
  }

  info(...args) {
    if (this.logSwitch) {
      console.info(...args)
    } else {
      return false
    }
  }

  warn(...args) {
    if (this.logSwitch) {
      console.warn(...args)
    } else {
      return false
    }
  }

  error(...args) {
    console.error(...args)
  }
}

const Log = new JsLog()
export default Log

window['log'] = Log
