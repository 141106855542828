import { createRouter, createWebHashHistory } from 'vue-router'

const Docs = () => import(/* webpackChunkName: "docs" */ '../views/Docs/index.vue')
const Home = () => import(/* webpackChunkName: "oxc-home" */ '../views/Home/index.vue')
const AboutMe = () => import(/* webpackChunkName: "oxc-me" */ '../views/AboutMe/index.vue')
const OXCInviter = () => import(/* webpackChunkName: "oxc-me" */ '../views/OXCInviter/index.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/me',
    name: 'AboutMe',
    component: AboutMe,
  },
  {
    path: '/my-inviter',
    name: 'OXCInviter',
    component: OXCInviter,
  },
  {
    path: '/docs',
    name: 'docs',
    component: Docs,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
