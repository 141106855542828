export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar Billetera"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonés"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreano"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruso"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemán"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugués"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árabe"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chino"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Personal"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invertir"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamar"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin Invitador"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi invitación"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace de Referencia"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar Enlace"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas de Inversión"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de Inversión"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Inversión"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Progreso"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuota Restante"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuota Total"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto Máximo de Ganancia"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas Máximas"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas Actuales"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto Liberado por Día"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversión"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi Inversión"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO ya Intercambiado"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ya Intercambiado"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar en el Intercambio"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one mortgage level can be selected for each account at the same time. \nAfter the release is completed and the rewards are claimed, you can choose to participate in staking again."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only users who are mining are eligible to receive invitation rewards. Invitations sent by users who are not mining will not receive rewards."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total invitation rewards"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation link"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining records"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient token balance"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the inviter"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid address"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The inviter is invalid. The inviter must be a registered user!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users who invite new users to participate in the project can receive corresponding rewards. \nInvitation rewards are calculated separately according to different levels, as follows:"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Reward"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Invitations"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation Incentives"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining Count"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incentive rate"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rewards"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Rewards"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enable"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Price"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Invitation Rewards"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlocked Rewards"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked Rewards"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including"])}
}