export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter le portefeuille"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonais"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coréen"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russe"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabe"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investir"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réclamer"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'invitant"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon invitation"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien de référence"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses d'investissement"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant investi"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'investissement"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quota restant"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quota total"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximal de profit"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes maximum"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes actuels"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de libération chaque jour"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon investissement"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO déjà échangé"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déjà échangé"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer à l'échange"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un seul niveau de prêt hypothécaire peut être sélectionné pour chaque compte à la fois. \nUne fois la publication terminée et les récompenses réclamées, vous pouvez choisir de participer à nouveau au staking."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les utilisateurs qui exploitent sont éligibles pour recevoir des récompenses d'invitation. Les invitations envoyées par des utilisateurs qui n'exploitent pas ne recevront pas de récompenses."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses totales des invitations"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien d'invitation"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers miniers"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'enregistrements"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde de jetons insuffisant"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer l'invitant"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse invalide"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’invitant n’est pas valide. L’invitant doit être un utilisateur enregistré !"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs qui invitent de nouveaux utilisateurs à participer au projet peuvent recevoir les récompenses correspondantes. \nLes récompenses d'invitation sont calculées séparément selon différents niveaux, comme suit :"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompense en attente"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total d'invitations"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incitations aux invitations"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gains"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte minier"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarif incitatif"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation minière"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses totales"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses quotidiennes"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restant"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activer"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix ​​d'achat"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses d'invitation en attente"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses débloquées"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récompenses verrouillées"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y compris"])}
}