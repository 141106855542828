import AppLayout from "./components/Layout/AppLayout.vue";
import { useInitialI18n } from "./composition/index";
import { defineComponent } from "vue";
import Log from "./utils/log";
export default defineComponent({
  components: {
    AppLayout
  },
  setup() {
    const {
      locale
    } = useInitialI18n();
    return {
      locale
    };
  },
  mounted() {
    // Silent Login
    this.$i18n.locale = this.locale;
    this.$store.dispatch("silentLogin").then(() => {
      this.$store.dispatch("getAIBalance");
    }).then(() => {
      this.$store.dispatch("getApprovedCountAI");
      this.$store.dispatch("getApprovedCountUSDTToIDO");
      this.$store.dispatch("getApprovedCountDAOToIDO");
    }).then(() => {
      this.$store.dispatch("getUserInfo");
      this.$store.dispatch("userLastInvestFinished");
    }).then(() => {
      this.$store.dispatch("getPendingRewards");
    }).catch(err => {
      Log.error("=== silentLogin ===", err);
    });
    setTimeout(() => {
      this.$store.dispatch("getInvestProducts");
      this.$store.dispatch("getAIIEOInfo");
      this.$store.dispatch("getAIICOInfo");
    }, 1000);
  }
});