export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصال المحفظة"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليابانية"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكورية"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهندية"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروسية"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألمانية"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرنسية"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرتغالية"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسبانية"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصينية"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات شخصية"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالفعل"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استثمار"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المطالبة"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المُحال"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مدعو"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوتي"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط المُحال"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ الرابط"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكافآت الاستثمار"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الاستثمار"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستثمار"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التنفيذ"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمل"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شخص"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حصة متبقية"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة الكلية"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقصى مبلغ ربح"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للحسابات"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات الحالية"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الإصدار يوميًا"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رهن"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استثماري"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تبادل IDO بالفعل"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تبادل بالفعل"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاركة في التبادل"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جزء"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينهي"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن اختيار مستوى رهن عقاري واحد فقط لكل حساب في نفس الوقت. \nبعد اكتمال الإصدار والحصول على المكافآت، يمكنك اختيار المشاركة في الستاكينغ مرة أخرى."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فقط المستخدمون الذين يقومون بالتعدين هم وحدهم المؤهلون للحصول على مكافآت الدعوة، أما الدعوات المرسلة من قبل المستخدمين الذين لا يقومون بالتعدين فلن تحصل على مكافآت."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي مكافآت الدعوة"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الدعوة"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجلات التعدين"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تسجيلات"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رصيد رمزي غير كافي"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال الداعي"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان خاطئ"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الداعي غير صالح. يجب أن يكون الداعي مستخدمًا مسجلاً!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للمستخدمين الذين يدعون مستخدمين جدد للمشاركة في المشروع الحصول على المكافآت المقابلة. \nيتم احتساب مكافآت الدعوة بشكل منفصل وفقًا لمستويات مختلفة، على النحو التالي:"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكافأة العالقة"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الدعوات"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حوافز الدعوة"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرباح"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد التعدين"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الحافز"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعدين"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المكافآت"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكافآت اليومية"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متبقي"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يُمكَِن"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يشتري"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر شراء"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكافآت الدعوة المعلقة"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكافآت مقفلة"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكافآت المقفلة"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشتمل"])}
}