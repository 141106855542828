export const useInitialI18n = () => {
  let locale = localStorage.getItem('locale')

  if (locale !== 'zh' && locale !== 'en') locale = 'en'

  localStorage.setItem('locale', locale)

  return {
    locale,
  }
}
