export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить кошелек"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Японский"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корейский"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хинди"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французский"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Португальский"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Испанский"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арабский"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Китайский"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональная информация"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвестировать"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реферрер"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет приглашающего"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои приглашения"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реферальная ссылка"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвестиционные награды"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма инвестиций"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата инвестиций"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процессе"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остаток квоты"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая квота"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная сумма прибыли"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество аккаунтов"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущее количество аккаунтов"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма выпуска каждый день"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стейк"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои инвестиции"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO уже обменяно"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["уже обменяно"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество IDO"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участвовать в обмене"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часть"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заканчивать"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для каждого счета одновременно можно выбрать только один уровень ипотеки. \nПосле завершения релиза и получения вознаграждений вы можете снова принять участие в стейкинге."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только пользователи, которые занимаются майнингом, имеют право на получение вознаграждений за приглашения. Приглашения, отправленные пользователями, которые не занимаются майнингом, не получат вознаграждения."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма вознаграждений за приглашение"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для приглашения"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записи горного дела"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет записей"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточный баланс токенов"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите приглашающего"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный адрес"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашающий недействителен. Приглашающий должен быть зарегистрированным пользователем!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи, приглашающие новых пользователей к участию в проекте, могут получить соответствующие вознаграждения. \nНаграды за приглашения рассчитываются отдельно в зависимости от разных уровней следующим образом:"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидается награда"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего приглашений"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение Стимулы"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заработок"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество майнинга"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поощрительная ставка"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добыча"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего наград"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневные награды"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставшийся"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["давать возможность"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена покупки"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемые награды за приглашения"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокированные награды"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокированные награды"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включая"])}
}