export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連接錢包"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日語"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓語"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印地語"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俄語"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["德語"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法語"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葡萄牙語"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西班牙語"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿拉伯語"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文繁體"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人信息"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餘額"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已鑄造"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待鑄造"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領取"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請人"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫無邀請人"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我邀請的人"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請鏈接"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製鏈接"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造記錄"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造收益"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造數量"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造時間"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等級"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礦池額度"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總礦池"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造率"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餘額"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日鑄造"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鑄造"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的投入"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO已兌換"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已兌換"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO總量"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與兌換"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每個帳戶只能同時選擇一個抵押等級。\n在釋放完畢並領取獎勵後，您可以再次選擇參與抵押。"])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在進行挖礦的用戶才有資格獲得邀請獎勵，未進行挖礦的用戶發出的邀請將不會獲得獎勵。"])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總邀請獎勵"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請連結"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖礦記錄"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫無紀錄"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代幣餘額不足"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入邀請人"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無效的地址"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請人無效，邀請人必須是已註冊使用者！"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶邀請新用戶參與項目，可獲得相應獎勵。\n邀請獎勵依不同等級分別計算，如下所示："])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待領取獎勵"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總推薦數"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請獎勵機制"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["效益"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖礦次數"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀請獎勵比例"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖礦"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總獎勵"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日獎勵"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總量"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啟用"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搶購"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搶購金額"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待釋放邀請獎勵"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解鎖的獎勵"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待解鎖的獎勵"])},
  "Release Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["釋放天數"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])}
}