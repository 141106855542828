export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットに接続"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヒンディー語"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロシア語"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドイツ語"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス語"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポルトガル語"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペイン語"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラビア語"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国語"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既に"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介者"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待者なし"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の招待"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介リンク"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクをコピー"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記録"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資報酬"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資額"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資日"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残りクオータ"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総クオータ"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大利益額"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大アカウント数"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のアカウント数"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日のリリース量"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステーク"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の投資"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDOは既に交換されています"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は既に交換されています"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDOの総量"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換に参加する"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各アカウントに対して同時に選択できる住宅ローン レベルは 1 つだけです。\nリリースが完了して報酬を受け取った後、再度ステーキングに参加することを選択できます。"])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイニングを行っているユーザーのみが招待報酬を受け取る資格があり、マイニングを行っていないユーザーが送信した招待は報酬を受け取ることができません。"])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待報酬の合計"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待リンク"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["採掘記録"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記録なし"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン残高不足"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待者を入力してください"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なアドレス"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待者は無効です。招待者は登録ユーザーである必要があります。"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいユーザーをプロジェクトに参加するよう招待したユーザーは、対応する報酬を受け取ることができます。\n招待の報酬は、次のようにレベルに応じて個別に計算されます。"])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の報酬"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待総数"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待インセンティブ"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["収益"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイニング数"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インセンティブ率"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイニング"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総報酬額"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日の報酬"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残り"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効にする"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買う"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入価格"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の招待報酬"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロック解除された報酬"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロックされた報酬"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含む"])}
}