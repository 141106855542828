import { default as axios } from './axios'
import Log from '../utils/log'
import { formatSubordinatesData } from './util'

export default {
  // `所有下级地址`
  getSubordinates(address) {
    return axios
      .post('/v1/subordinates', { address, isTestnet: true })
      .then(res => res.data.data)
      .catch(error => Log.error('===== getSubordinates =====', error))
  },

  // `当前 & 下属` 质押总额
  getSubordinatesInvest(address) {
    return axios
      .post('/v1/subordinates_invest', { address, isTestnet: true })
      .then(res => res.data.data)
      .catch(error => Log.error('== getSubordinatesInvest ==', error))
  },

  getFormatSubordinatesInfo({ address }) {
    return Promise.all([this.getSubordinates(address), this.getSubordinatesInvest(address)])
      .then(([subordinates, subordinateInvest]) => {
        Log.info('====  getFormatSubordinatesInfo =====', subordinates, subordinateInvest, address)

        return formatSubordinatesData([subordinates, subordinateInvest, address])
      })
      .catch(err => {
        Log.error('====  getFormatSubordinatesInfo =====', err)
      })
  },
}
