import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import logo from '../../assets/logo.png';
import iconArrowDown from '../../assets/icon_arrow_down.png';
export default defineComponent({
  name: 'LayoutHeader',
  components: {},
  computed: {
    ...mapState({
      shortAddress: state => state.shortAddress
    })
  },
  data() {
    return {
      logo,
      iconArrowDown
    };
  },
  mounted() {
    //  this.$i18n.availableLocales availableLocales
    setTimeout(() => {
      this.$store.dispatch('getInvestProducts');
      this.$store.dispatch('getAIIEOInfo');
      this.$store.dispatch("getAIICOInfo");
    }, 1000);
  },
  methods: {
    filterLanguage(flag) {
      console.log(flag);
      const langStr = {
        en: "English",
        ja: "Japanese",
        ko: "Korean",
        hi: "Hindi",
        ru: "Russian",
        ge: "German",
        fr: "French",
        po: "Portuguese",
        sp: "Spanish",
        ar: "Arabic",
        "zh-TW": "Chinese"
      }[flag];
      return this.$t(`Lang ${langStr}`);
    },
    handleSelect(command) {
      this.$i18n.locale = command;
      localStorage.setItem('locale', command);
      this.$store.dispatch("changeLocaleI18n", command);
    },
    handleLogin: function () {
      this.$store.dispatch("loginMetamask").then(() => {
        this.$store.dispatch('getAIBalance');
      }).then(() => {
        this.$store.dispatch('getApprovedCountAI');
        this.$store.dispatch('getApprovedCountUSDTToIDO');
        this.$store.dispatch("getApprovedCountDAOToIDO");
      }).then(() => {
        this.$store.dispatch('getUserInfo');
        this.$store.dispatch("userLastInvestFinished");
      }).then(() => {
        this.$store.dispatch('getPendingRewards');
      }).then(() => {
        this.$store.dispatch('getInvestProducts');
        this.$store.dispatch('getAIIEOInfo');
        this.$store.dispatch("getAIICOInfo");
      });
    }
  }
});