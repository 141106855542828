import Log from './log'

export const computeDeviceModal = () => {
  const width = window.innerWidth

  const medias = [
    window.matchMedia('(max-width:418px)'),
    window.matchMedia('(max-width:768px)'),
    window.matchMedia('(max-width:900px)'),
    window.matchMedia('(max-width:1200px)'),
  ]

  if (medias[0].matches) {
    Log.log(` ==== width is ${width} & <=418 ==== `)
  } else if (medias[1].matches) {
    Log.log(` ==== width is ${width} & >418 & <=768 ==== `)
  } else if (medias[2].matches) {
    Log.log(` ==== width is ${width} & >768 & <=992 ==== `)
  } else if (medias[3].matches) {
    Log.log(` ==== width is ${width} & > 992 & <=1200 ==== `)
  } else {
    Log.log(` ==== width is ${width} & >1200 ==== `)
  }

  if (medias[0].matches || medias[1].matches || medias[2].matches) {
    return {
      isMobile: true,
      client: 'Mobile',
    }
  } else {
    return {
      isMobile: false,
      client: 'PC',
    }
  }
}
