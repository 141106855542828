import { defineComponent } from 'vue';
import './index';
export default defineComponent({
  name: 'LayoutHeader',
  components: {},
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      return this.name ? `#icon-${this.name}` : '';
    }
  }
});