export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑 연결"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일본어"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
  "Lang Hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["힌디어"])},
  "Lang Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["러시아어"])},
  "Lang German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["독일어"])},
  "Lang French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프랑스어"])},
  "Lang Portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포르투갈어"])},
  "Lang Spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페인어"])},
  "Lang Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아랍어"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중국어"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 정보"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔고"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청구"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천인"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대자 없음"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 초대"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천 링크"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크 복사"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기록"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자 보상"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자 금액"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자 날짜"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사람"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔여 할당량"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 할당량"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 이익 금액"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 계정 수"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 계정 수"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일 출시 금액"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스테이크"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 투자"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO 이미 교환됨"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 교환됨"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO 총량"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 참여"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부분"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마치다"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 계좌에 대해 동시에 하나의 모기지 수준만 선택할 수 있습니다. \n출시가 완료되고 보상을 받은 후 다시 스테이킹에 참여하도록 선택할 수 있습니다."])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채굴 중인 사용자만 초대 보상을 받을 수 있으며, 채굴을 하지 않는 사용자가 보낸 초대에는 보상을 받을 수 없습니다."])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 초대 보상"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대링크"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채굴 기록"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기록 없음"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 잔액 부족"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대자를 입력해주세요."])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 주소"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대자가 유효하지 않습니다. 초대자는 등록된 사용자여야 합니다!"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 사용자를 프로젝트에 참여하도록 초대한 사용자는 그에 상응하는 보상을 받을 수 있습니다. \n초대 보상은 다음과 같이 레벨에 따라 별도로 계산됩니다."])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중인 보상"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 초대수"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대 인센티브"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수입"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채굴 횟수"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인센티브 비율"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채광"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 보상"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일일 보상"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남은"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["~할 수 있게 하다"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구입하다"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 가격"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중인 초대 보상"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠금 해제된 보상"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠긴 보상"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["낮"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함"])}
}