export const CHAIN_ID_API_ENDPOINT = new Map([
  [1, 'https://geth.mytokenpocket.vip'],
  [56, 'https://bsc-dataseed.binance.org'],
  [97, 'https://data-seed-prebsc-1-s1.binance.org:8545'],
  [66, 'https://exchainrpc.okex.org'],
  [65, 'https://exchaintestrpc.okex.org'],
])

export const CHAIN_ID_MAP = new Map([
  [1, 'ETH\nMainnet'],
  [56, 'BSC\nMainnet'],
  [97, 'BSC\nTestNet'],
  [66, 'OKExChain\nMainnet'],
  [65, 'OKExChain\nTestNet'],
])
