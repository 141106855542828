import Head from './Header.vue';
import Main from './Main.vue';
import Footer from './Footer.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AppLayout',
  components: {
    Head,
    Main,
    Footer
  },
  computed: {
    showFooter() {
      return this.$router.currentRoute.value.path == '/' || this.$router.currentRoute.value.path == '/me';
    }
  }
});