export default {
  "Connect Wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
  "Lang English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Lang Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文简体"])},
  "Lang Japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "Lang Korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已铸造"])},
  "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待铸造"])},
  "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取"])},
  "Reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请人"])},
  "No Inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无邀请人"])},
  "My invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我邀请的人"])},
  "Reffer Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请链接"])},
  "Copy Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
  "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造记录"])},
  "Invest Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造收益"])},
  "Invest Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造数量"])},
  "Invest Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造时间"])},
  "Inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级"])},
  "Rest Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矿池额度"])},
  "Total Quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总矿池"])},
  "Max Profit Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造率"])},
  "Max Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
  "Current Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "Release Amount EachDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日铸造"])},
  "Mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造"])},
  "My Investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的投入"])},
  "IDO Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO已兑换"])},
  "Already Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已兑换"])},
  "IDO Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDO总量"])},
  "Participate In Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与兑换"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
  "Portion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["份"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束"])},
  "Mint Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个账户只能同时选择一个抵押等级。在释放完毕并领取奖励后，您可以再次选择参与抵押。"])},
  "Invite Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在进行挖矿的用户才有资格获得邀请奖励，未进行挖矿的用户发出的邀请将不会获得奖励。"])},
  "Total Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总邀请奖励"])},
  "Invitation Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请链接"])},
  "mint log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖矿记录"])},
  "No Records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无记录"])},
  "balance is insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币余额不足"])},
  "Please enter the inviter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入邀请人"])},
  "Invalid Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的地址"])},
  "Inviter is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请人无效，邀请人必须是已注册用户！"])},
  "Invite Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户邀请新用户参与项目，可获得相应奖励。邀请奖励根据不同级别分别计算，如下所示："])},
  "Pending Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待领取奖励"])},
  "Total Invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总推荐数"])},
  "Invitation Incentives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请奖励机制"])},
  "Mint Earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖矿收益"])},
  "Mint Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖矿次数"])},
  "Incentive Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请奖励比例"])},
  "Mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["挖矿"])},
  "Total Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总收益"])},
  "Daily Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日收益"])},
  "Total Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总量"])},
  "Surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余"])},
  "Enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抢购"])},
  "Buy Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抢购金额"])},
  "Pending Invitation Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待释放邀请奖励"])},
  "Unlocked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解锁的奖励"])},
  "Locked Rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待解锁奖励"])},
  "Release Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["释放天数"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
  "Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])}
}