import Log from "../../utils/log";
// INFO: 合约变量
const isProd = process.env.VUE_APP_CONTRACT_ENV == "production";

// * BSC测试网
// HIGH: TEST Net Contract
const DEV = {
    ENV: "DEV",
    // OXCTokenAddress: '0xc170524d0F3E787685000e99B05bF64b33CB8F4D',
    // InvestOXCAddress: '0x60F7320B21e3Ce58eF897285bDBea1D4E5331072',
    // UsdtAddress: '0x7EA8Eaf3ECD615eda2d2cb1D6fcc5d072e6d87Ff',
    // OxcIDOAddress: '0xB417f4C115257A6A3FfB77130f0a7Da45045D3eB',
    AITokenAddress: "0xcBA708B4d0366E8c26e042ab6Dc640602918D86f",
    InvestAIAddress: "0x9424fDc006C37AE0525Eda6A864F916Cb45a4bAd",
    UsdtAddress: "0x32298C7ea19Dcc6454F71b811bB3503a27072766",
    DaoAddress: "0xd2Bccb109c09065B4F5d585D1753f698a9412d26",
    AIIEOAddress: "0xa6D016A59B72776f8817aF9a3b1413Bce7a7Fac5",
    AIICOAddress: "0xa1bd1d76355f128aA36B0172002BFc868f2BBB7B",
    DefaultUserAddress: "0x18D8003a3773c152CedF35B61A513AabE52F8b27",
};

// * BSC 主网
// HIGH: Main Net Contract
const MAIN = {
    ENV: "MAIN",
    AITokenAddress: "0xF9C57FdF310ca6a5c34B15385cD8b18207Ff7a74",
    InvestAIAddress: "0x8F3a9C1662c019c718045bD440f7b7fc88816C3C",
    UsdtAddress: "0x55d398326f99059fF775485246999027B3197955",
    DaoAddress: "0x037b202ca88d2028d82936d5615ee5088cb9fd78",
    AIIEOAddress: "0xd37e180D504848E387D4c9476c227D1Edca9Ac40",
    AIICOAddress: "0xBe701C1F6c088dB29716F3Cb1eBE6D631a90645e",
    DefaultUserAddress: "0x18D8003a3773c152CedF35B61A513AabE52F8b27",
};

const CONFIG = isProd ? MAIN : DEV;

Log.log("=== ContractAddress ENV ===", CONFIG);

export default CONFIG;
