import bignumber from 'bignumber.js'

const formatNumber = number => (number ? new bignumber(number).div(1e18).toNumber().toFixed(0) : 0)

const shortAddress = address => (address ? address.substring(0, 4) + '...' + address.substr(-4) : null)
export const formatSubordinatesData = ([subordinates, subordinateInvest, myAddress]) => {
  const target = {}
  const total = subordinateInvest.total ? new bignumber(subordinateInvest.total).div(1e18).toNumber() : 0
  let subordinatesNum = 0

  for (const key in subordinates) {
    if (key !== 'acc') {
      target[key] = subordinates[key].map(it => {
        return {
          level: key,
          address: shortAddress(it),
          amount: formatNumber(subordinateInvest[it]),
        }
      })
      subordinatesNum += subordinates[key].length
    }
  }
  return {
    subordinatesInfoUnderMe: target,
    subordinatesTotalUnderMe: total.toFixed(0),
    myInvestmentTotal: formatNumber(subordinateInvest[myAddress]),
    subordinatesNumUnderMe: subordinatesNum
  }
}
